<svg
	class="dropdown-arrow"
	xmlns="http://www.w3.org/2000/svg"
	width="100%"
	height="100%"
	viewBox="0 0 18 18"
>
	<path d="M5 8l4 4 4-4z" />
</svg>

<style>
	.dropdown-arrow {
		fill: currentColor;
		/* margin-right: var(--size-2); */
		/* width: var(--size-5); */
	}
</style>
